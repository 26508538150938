import firebase from "firebase/app";
import "firebase/database";
import { getGameSessionId, getParams } from "./utils";
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
if (window.location.hostname !== "localhost") {
	Sentry.init({
		dsn: "https://7b4b141e8082405dabcbf2082b416829@o422923.ingest.sentry.io/5883849",
		integrations: [new Integrations.BrowserTracing()],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
	});
}

const firebaseConfig = {
	apiKey: process.env.APP_KEY,
	authDomain: process.env.AUTH_DOMAIN,
	databaseURL: process.env.DATABASE_URL,
	projectId: process.env.PROJECT_ID,
	storageBucket: process.env.STORAGE_BUCKET,
	messagingSenderId: process.env.MESSAGING_SENDER_ID,
	appId: process.env.APP_ID,
	measurementId: process.env.MEASUREMENT_ID,
};

console.log(firebaseConfig);

const app = firebase.initializeApp(firebaseConfig);
console.log(app);
// const db = firebase.database(app);
console.log(firebase.database());
if (process.env.EMULATE) {
	const firebaseEmulators = {
		database: {
			host: "localhost",
			port: 9000,
		},
		functions: {
			host: "localhost",
			port: 5001,
		},
	};
	console.log("Automatically connecting Firebase SDKs to running emulators:");
	Object.keys(firebaseEmulators).forEach(function (key) {
		console.log("\t" + key + ": http://" + firebaseEmulators[key].host + ":" + firebaseEmulators[key].port);
	});
	if (firebaseEmulators.database && typeof firebase.database === "function") {
		firebase.database().useEmulator(firebaseEmulators.database.host, firebaseEmulators.database.port);
	}
	if (firebaseEmulators.firestore && typeof firebase.firestore === "function") {
		firebase.firestore().useEmulator(firebaseEmulators.firestore.host, firebaseEmulators.firestore.port);
	}
	if (firebaseEmulators.functions && typeof firebase.functions === "function") {
		firebase.functions().useEmulator(firebaseEmulators.functions.host, firebaseEmulators.functions.port);
	}
	if (firebaseEmulators.auth && typeof firebase.auth === "function") {
		firebase.auth().useEmulator("http://" + firebaseEmulators.auth.host + ":" + firebaseEmulators.auth.port);
	}
} else {
	console.log(
		"To automatically connect the Firebase SDKs to running emulators, replace '/__/firebase/init.js' with '/__/firebase/init.js?useEmulator=true' in your index.html"
	);
}

let roundValue = 1;

function getRoundValue() {
	return roundValue;
}

export const dbRoot = firebase.database().ref("truthAndDare");
export const dbGameSession = dbRoot.child(getGameSessionId());
export const dbUsers = dbGameSession.child("users");
export const dbUser = dbUsers.child(getParams("userId"));
export const dbHost = dbGameSession.child("host");
export const dbTheme = () => dbGameSession.child("theme"); // Refers to a round's theme
export const dbThemeId = () => dbGameSession.child("themeId"); // Refer to themeID of a game
export const dbThemeData = () => dbGameSession.child("themeData"); // Refer to theme data of a game
export const dbNoOfOnlineUsers = dbGameSession.child("noOfOnlineUsers");
export const dbGameSessionRoundValue = dbGameSession.child("roundValue");
export const dbGameSessionRounds = dbGameSession.child("rounds");
export const dbGameSessionIsSpinning = dbGameSession.child("isSpinning");
export const dbGameSessionBottleAngle = dbGameSession.child("bottleAngle");
export const dbChanceDisconnect = dbGameSession.child("chanceDisconnect");
export const dbAllTruths = dbRoot.parent.child("allTruths");
export const dbAllDares = dbRoot.parent.child("allDares");

export const dbGameSessionRound = () => dbGameSessionRounds.child(getRoundValue());

export const user = {
	id: getParams("userId"),
	userName: getParams("userName"),
	profilePicture: getParams("userProfilePicture"),
};

// This listener listens on to different datapoint or collection depending on the page
export function listenOnFirebaseKey(firebaseKey, callback, notExitCallback) {
	Promise.resolve(roundTimeValuePromise).then(() => {
		firebaseKey.on("value", snap => {
			if (snap.exists()) {
				callback(snap.val());
			} else {
				notExitCallback && notExitCallback();
			}
		});
	});
}

// This promise help the above listener to check if the game has started or not
const roundTimeValuePromise = new Promise(resolve => {
	dbGameSessionRoundValue.once("value").then(snap => {
		if (!snap.exists()) {
			roundValue = 1;
		} else {
			roundValue = snap.val();
		}
		resolve(0);
	});
});

// This listener keeps track of current round value
dbGameSessionRoundValue.on("value", snap => {
	if (!snap.exists()) {
		dbGameSessionRoundValue.set(1);
		dbGameSessionIsSpinning.set(false);
		dbGameSessionBottleAngle.set(45);
		roundValue = 1;
		return;
	}
	roundValue = snap.val();
});

var connectedRef = firebase.database().ref(".info/connected");
connectedRef.on("value", snap => {
	if (snap.val() === true) {
		dbUser.update({
			online: true,
			isOnline: true,
		});

		dbUser.onDisconnect().update({
			online: firebase.database.ServerValue.TIMESTAMP,
		});
	}
});
dbUser.update({
	id: getParams("userId"),
	userName: getParams("userName"),
	profilePicture: getParams("userProfilePicture"),
});

window?.DIVE_APP?.listenOnAppThemeChange(e => {
	console.log(e.th_id, "listenOnAppThemeChange");
	dbThemeId().set(e.th_id);
});
