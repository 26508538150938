export const params = new URLSearchParams(window.location.search);

export const getParams = function (name) {
	return "" + params.get(name);
};

export const getGameSessionId = function () {
	return `${getParams("roomId")}+${getParams("sessionId")}`;
};

export const getAngle = count =>
	new Array(count).fill(0).map((item, index) => [index * (360 / count), (index + 1) * (360 / count)]);
